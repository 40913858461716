<template>
  <div class="feesandcharges" id="feesandcharges">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > TayoCash Fees and Charges</p>
        <h3><span>TayoCash</span> Fees and Charges</h3>
        <h4 id="tcfc1">What are the applicable fees and charges when I use the TayoCash App and Services?</h4>
        <div class="paragraph">
          <p>The following are the applicable fees and charges you may encounter in using the TayoCash App and services:</p>
          <div class="table-div">
            <table class="ui celled structured table unstackable feesandchargestable">
              <thead>
                <tr>
                  <th rowspan="2" colspan="2">Transactions</th>
                  <th>Fees and Charges*</th>
                </tr>
                <tr>
                  <th>Current</th>
                  <!-- <th>New - Effective March 1, 2022</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="7">Cash In</td>
                </tr>
                <tr>
                  <td>via Bank/EMI Apps of <br> <a class="link" href="https://assets.prod.tayocash.com/forms/instapay_partners.pdf" download target="_blank">Instapay Partners</a></td>
                  <!-- <td>Fees are as charged by Instapay Partners</td> -->
                  <td>Fees are as charged by Instapay Partners</td>
                </tr>
                <tr>
                  <td>via Over-The-Counter (OTC) <br><a class="link" href="https://assets.prod.tayocash.com/docs/cashin_partners.pdf" download target="_blank">Partner Outlets</a></td>
                  <!-- <td>0.90%</td> -->
                  <td>
                    0.90% for Cash in below Php 2,000, Free for Cash in amounting to Php 2,000 and above
                  </td>
                </tr>
                <tr>
                  <td>via OTC UnionBank Branches</td>
                  <!-- <td>0% Service Fee + Php 15 Bank Charge</td> -->
                  <td>0% Service Fee + Php 15 Bank Charge</td>
                </tr>
                <tr>
                  <td>via Linked Bank Account in TayoCash App <br> (Available to UnionBank account holders only)</td>
                  <!-- <td>FREE</td> -->
                  <td>FREE</td>
                </tr>
                <tr>
                  <td>via Online Bills Payment Platform <br> (Available to UnionBank account holders only)</td>
                  <!-- <td>FREE</td> -->
                  <td>FREE</td>
                </tr>
                <tr>
                  <td>via Online Banking <br>(RCBC and BPI via BUX)</td>
                  <!-- <td>0.90%</td> -->
                  <td>Php 15</td>
                </tr>

                <tr>
                  <td rowspan="2">Bank Transfer</td>
                  <td>via Instapay</td>
                  <!-- <td>FREE</td> -->
                  <td>Php 12</td>
                </tr>
                <tr>
                  <td>via Pesonet</td>
                  <!-- <td>Not yet available</td> -->
                  <td>Php 5</td>
                </tr>

                <tr>
                  <td>Cash Out</td>
                  <td>via OTC Partner Outlets <br><a class="link" href="https://assets.prod.tayocash.com/docs/cashout_partners.pdf" download target="_blank">Partner Outlets</a></td>
                  <!-- <td>Not yet available</td> -->
                  <td>
                    New - Effective May 17, 2022
                    <br>
                    1.25% of Cash Out amount
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">Pay Bills</td>
                  <td>Pay Bills with Fixed Convenience Fees <br>(Actual fees per biller are detailed in TayoCash app)</td>
                  <!-- <td>Php 0 Service Fee + Php 0 to Php 100 Biller`s Convenience Fee</td> -->
                  <td>Php 0 Service Fee + Php 0 to Php 100 Biller`s Convenience Fee</td>
                </tr>
                <tr>
                  <td>Billers with Percentage-based Convenience Fees <br>(Actual fees per biller are detailed in TayoCash app)</td>
                  <!-- <td>0% Service Fee + 0 to 2% Biller`s Convenience Fee</td> -->
                  <td>0% Service Fee + 0 to 2% Biller`s Convenience Fee</td>
                </tr>

                <tr>
                  <td>Buy Load</td>
                  <td>Buy Load Menu is detailed in the TayoCash app</td>
                  <!-- <td>Php 0 Service Fee</td> -->
                  <td>Php 0 Service Fee</td>
                </tr>
                <tr>
                  <td>Fund Transfer</td>
                  <td>From TayoCash Wallet to another TayoCash Wallet</td>
                  <!-- <td>FREE</td> -->
                  <td>FREE</td>
                </tr>
                <tr>
                  <td>Remittance</td>
                  <td>Not yet available</td>
                  <!-- <td>Not yet available</td> -->
                  <td>Not yet available</td>
                </tr>
                <tr>
                  <td rowspan="2">General</td>
                  <td>Dormancy Fee</td>
                  <!-- <td>FREE</td> -->
                  <td>FREE</td>
                </tr>
                <tr>
                  <td>Reactivation  Fee</td>
                  <!-- <td>FREE</td> -->
                  <td>FREE</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            * All applicable TayoCash fees and charges are subject to change upon a 30-day prior notice. <br>
            * Bank charges and biller fees are subject to change depending on bank and biller partners.
          </p>
          <div class="buttons">
            <a href="/faq/accountsecurity" class="yellow">BACK</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'feesandcharges'
}
</script>

<style scoped lang='scss'>
</style>
